<template>
    <div class="task-item">
        <div class="head">
          <span style="flex: 1;">
            <span>{{ dataSource.taskUuid }}</span>
            <span class="taskName ml10">{{ dataSource.taskName }}</span>
          </span>
          <span :class="`status_${dataSource.taskStatus}`">{{ dataSource.taskStatus | codeFormat }}</span>
        </div>
        <div class="content">
          <shortConsumerInfo :data="dataSource" :showLevel="false" :showIntention="false" :showSex="false" :option="option"/>
          <div class="field-list">
            <div v-for="field in list" :key="field.key">
                <span class="label" :style="{color: field.key==='yuqi' || field.key==='timeline'?'red':''}">{{ field.label }}：</span>
                <span class="value" :style="{color: field.key==='yuqi' || field.key==='timeline'?'red':''}">
                  <span v-if="field.key==='yuqi'">{{ getYuQiTime('overdue') }}</span>
                  <span v-else-if="field.key==='timeline'">{{ getYuQiTime('timeline') }}</span>
                  <span v-else-if="field.key==='planTime'">{{ dataSource[field.key] ? dayjs(dataSource[field.key]).format('MM-DD HH:mm') : '' }}</span>
                  <span v-else-if="field.key==='lead'">{{ dataSource[field.key] && dataSource[field.key].state | codeFormat }}</span>
                  <span v-else>{{ dataSource[field.key] }}</span>
                </span>
            </div>
          </div>
        </div>
        <div class="footer">
          <span>{{ $t('创建时间') }}：</span>
          <span>{{ dataSource.createTime }}</span>
        </div>
    </div>
</template>
<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import dayjs from 'dayjs'
export default {
  components:{ shortConsumerInfo },
  props:{
    dataSource:{
      type:Object,
      default: ()=>({})
    }
  },
  data(){
    return {
      dayjs,
      option: {
        name: 'customerName',
        phone: 'customerMobile',
      }
    }
  },
  computed: {
    list(){
      const arr = [
        { label: this.$t('实际执行人'),key: 'executorName' },
        { label: this.$t('任务跟进人'),key: 'planExecutorName' },
        { label: this.$t('截止时间'),key: 'planTime' },
        // { label: '逾期',key: 'yuqi' },
        { label: this.$t('实际完成时间'),key: 'execTime' },
        { label: this.$t('线索状态'),key: 'lead' },
      ]
      if (this.getYuQiTime('overdue')){
        arr.push({ label: this.$t('逾期时长'),key: 'yuqi' })
      }
      if (this.getYuQiTime('timeline')){
        arr.push({ label: this.$t('任务倒计时'),key: 'timeline' })
      }
      return arr
    }
  },
  methods:{
    getYuQiTime(type) {
      // 截止时间
      const planTime = this.$moment(this.dataSource.planTime).format('YYYY-MM-DD HH:mm:ss')
      // 完成时间
      const execTime = this.dataSource.execTime ? this.$moment(this.dataSource.execTime) : this.$moment()
      
      let flag = false
      let diff = ''
      if (type === 'overdue') {
        flag = execTime.diff(planTime, 'hours') > 0
      } else {
        flag = this.$moment().diff(planTime, 'seconds') <= 0 && this.$moment().diff(planTime, 'hours') > -24 && !this.dataSource.execTime
      }
      if (flag) {
        if (type === 'overdue') {
          diff = execTime.diff(planTime, 'seconds')
        } else {
          diff = Math.abs(this.$moment().diff(planTime, 'seconds'))
        }
        // 将秒数转换为天和小时
        const days = Math.floor(diff / (24 * 3600))
        const hours = Math.floor((diff % (24 * 3600)) / 3600)
        const minutes = Math.ceil((diff % (24 * 3600)) % 3600 / 60) // 向上取整
        const str1 = days ? `${days} day ` : ''
        const str2 = hours ? `${hours} h ` : ''
        const str3 = minutes ? `${minutes} min` : ''
        return str1.concat(str2).concat(str3)
      } else {
        return ''
      }
    },
    getState(lead) {
      const obj = this.$store.getters.getDictHash(2000).find(item=>item.code === lead.state)
      return obj && obj.name 
    }
  }
}
</script>
<style lang="less" scoped>
.task-item{
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  font-size: 13px;
  line-height: 16px;
  font-family: HYQiHei, HYQiHei;
  .head{
    height: 28px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(13,23,26,0.05);
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    font-size: 13px;
    color: #0D171A;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    .taskName{
      color: #0040C5;
      padding-left: 8px;
      position: relative;
      margin-left: 8px;
      &::before{
        position: absolute;
        content: '';
        width: 1px;
        height: 14px;
        background: #d9d9d9;
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .content{
    border-bottom: 1px solid rgba(13,23,26,0.05);;
    padding-bottom: 6px;
    padding-top: 8px;
    margin-bottom: 6px;
    
    .field-list{
      display: flex;
      flex-wrap: wrap;
      
      .label{
        color: rgba(13,23,26,0.45);
      }
      .value{
        color: #0D171A;
      }
      &>div{
        min-width: 50%;
        max-width: max-content;
        margin-top: 8px;
      }
    }
  }
  .ml10{
    margin-left: 10px;
  }
  // 待执行
  .status_1025001{
    color: @black;
  }
  // 已完成
  .status_1025002{
    color: green;
  }
  // 已取消
  .status_1025003{
    color: #0d171a;
  }
  // 超时取消
  .status_1025004{
    color: red;
  }
  // 已转移
  .status_1025005{
    color: #ff7d00;
  }
}
</style>