<template>
  <div class="taskManage">
    <div class="taskManage-l">
      <div class="type-title">{{ $t('业务分类') }}</div>
      <div v-for="(item,index) in tabs" :key="index" :class="[type === item.value?'current':'','type-item']" @click="changeTab(item)">{{ item.name }}({{ item.num }})</div>
    </div>
    <div class="taskManage-r">
      <div class="top-wrap">
        <div class="filter-panel">
          <CalendarPicker ref="calendarPicker" v-model="selectTime" :defaultType="defaultDateType" :minDate="minDate" :maxDate="maxDate" @change="dateConfirm"/>
          <img class="img" :src="Object.values(filterParams).length > 0 ? filterActiveIcon : filterIcon" @click="filterPanelVisible = true"/>
        </div>
        <div class="sub-tab">
          <div v-for="subTab in subTabList" :key="subTab.value" :class="[subType.includes(subTab.value)?'active':'','sub-item']" @click="onChange(subTab)">{{ subTab.name }}</div>
        </div>
        <ListSort :options="sortOptions" @change="onSort"></ListSort>
      </div>
      <div class="total">{{ $t('共') }} {{ totalCount }} {{ $t('条数据') }}</div>
      <List ref="list" :fetchApi="fetchApi" class="task-manage-list">
        <template v-slot:default="{ item, index }">
          <Card :dataSource="item" @click.native="toDetails(item)"/>
        </template>
      </List>
    </div>
    <FilterPanel
        ref="filterPanel"
        v-model="filterPanelVisible"
        :options="filterOptions"
        @submit="onFilter"
      />
  </div>
</template>

<script>
import Card from './components/card.vue'
import List from '@/components/baseList/list.vue'
import loading from '@/utils/loading'
import CalendarPicker from './components/calendarPicker.vue'
import filterIcon from '@/images/filter.svg'
import filterActiveIcon from '@/images/filter_active.svg'
import FilterPanel from '@/components/filter-panel'
import ListSort from '@/components/list-sort/Index.vue'

import { v4 as uuidv4 } from 'uuid'
import { taskStatistic,taskListFetch } from '@/services/taskManage.js'
import { mapGetters } from 'vuex'
import { maxDate, minDate } from '../clue/config'
import dayjs from 'dayjs'
import baseDataServices from '@/services/baseDataServices'
import salesAssistantService from '@/services/salesAssistant'

export default {
  name: 'TaskManage',
  components:{ Card, List, CalendarPicker, filterIcon, FilterPanel, ListSort },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'TaskManage')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/delivery-assistant','/salesAssistant','/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.$refs.filterParams = {}
      this.$refs.filterPanel.reset()
      this.$store.commit('app/deleteKeepAlive', 'TaskManage')
    }
    next()
  },
  data() {
    const that = this
    return {
      sort: [{
        field: 'plan_time',
        asc: true
      }],
      sortOptions: [
        {
          field: 'plan_time',
          text: this.$t('截止时间'),
          order: 'ascend'
        },
        {
          field: 'exec_time',
          text: this.$t('实际完成时间')
        },
        {
          field: 'create_time',
          text: this.$t('创建时间')
        },
      ],
      filterIcon,
      filterActiveIcon,
      filterPanelVisible: false,
      type: 1,
      tabs:[
        {
          name: this.$t('线索'),
          value: 1,
          get num() {
            return that.countInfo[1] || 0
          }
        },
        {
          name: this.$t('试驾'),
          value: 2,
          get num() {
            return that.countInfo[2] || 0
          }
        },
        {
          name: this.$t('订单'),
          value: 3,
          get num() {
            return that.countInfo[3] || 0
          }
        },
        {
          name: this.$t('交付'),
          value: 4,
          get num() {
            return that.countInfo[4] || 0
          }
        },
        {
          name: this.$t('企微任务'),
          value: 101,
          get num() {
            return that.countInfo[101] || 0
          }
        }
      ],
      subType: [],
      countInfo:{},
      filterOptions: [],
      totalCount: 0,
      // 日历可选择的日期范围
      maxDate: dayjs().add(1,'year').toDate(),
      minDate: dayjs().subtract(1,'year').toDate(),
      // 筛选参数
      selectTime: [],
      filterParams:{
        // execTime: {
        //   start: '',
        //   end: '',
        // },
        // taskStatus: [],
      },
      defaultDateType: 'today',
      roleCode: '', // 从销售首页-跟进量跳转的角色
      isFirst: true,
    }
  },
  computed:{
    ...mapGetters(['dictHash','userInfo']),
    subTabList(){
      let list = []
      switch (this.type) {
        case 1:
          list = [
            // { name: this.$t('全部'), value: 0 },
            { name: this.$t('首次待跟进'), value: 'LEAD_FIRST_FOLLOW', },
            { name: this.$t('待跟进'), value: 'LEAD_FOLLOW', },
            // { name: this.$t('即将逾期'), value: 'LEAD_FOLLOW_FUTURE', },
            // { name: this.$t('已逾期'), value: 'LEAD_OVERDUE', },
            { name: this.$t('战败回访'), value: 'DEFEAT_FOLLOW', },
            { name: this.$t('无效回访'), value: 'INVALID_FOLLOW', },
          ] 
          break
        case 2:
          list = [
            // { name: this.$t('全部'), value: 0, },
            { name: this.$t('排程待确认'), value: 'APPOINT_TO_CONFIRM', },
            { name: 'PDC', value: 'APPOINT_PDC', },
            { name: this.$t('待试驾'), value: 'APPOINT_FOLLOW', },
            { name: this.$t('试驾回访'), value: 'AFTER_DRIVE_FOLLOW', },
            { name: this.$t('到店指引'), value: 'APPOINT_STORE_PDC', },
          ]
          break
        case 3: 
          list = [
            // { name: this.$t('全部'),value:0 },
            { name: this.$t('待分配订单'),value: 'ORDER_ASSIGN' },
            { name: this.$t('待锁单'),value: 'ORDER_LOCK' },
            { name: this.$t('待推送合同'),value: 'ORDER_CONTRACT_PUSH' },
            { name: this.$t('待签署合同'),value: 'ORDER_CONTRACT_SIGN' },
          ]
          break
        case 4: 
          list = [{ name: this.$t('交付回访'),value: 'DELIVERY_VISIT' }]
          break
        case 101: 
          list = [
            // { name: this.$t('全部'),value:0 },
            { name: this.$t('加微任务'),value: 'LEAD_INVITE_ADD_WECHAT' },
            { name: this.$t('建群任务'),value: 'CHAT_GROUP_TASK' },
            { name: this.$t('欢迎语任务'),value: 'CHAT_WELCOME_MSG' },
            { name: this.$t('添加群成员任务'),value: 'CHAT_ADD_MEMBER' },
            { name: this.$t('创建微信线索任务'),value: 'WECHAT_TO_LEAD' },
          ]
          break
        default:
          break
      }
      return list
    },
    isDealer() {
      return this.userInfo.type === 1 
    },
  },
  watch: {
    dictHash: {
      immediate: true,
      async handler(val) {
        if (val) {
          // 创建过滤条件
          this.createFilterOptions()
        }
      },
    },
    filterPanelVisible:{
      handler(newVal){
        if (newVal){
          setTimeout(() => {
            const { taskStatus,execTimeStart,execTimeEnd } = this.$route.query
            const list = this.dictHash['1025']
            const status = taskStatus?.split(',')
            const statusList = list.filter(e=>status.includes(e.code)).map(({ id,name,code })=>({
              id,
              label: name,
              value: code
            }))
            if (!this.isFirst) return
            this.$refs.filterPanel.formData.execTimeStart = dayjs(execTimeStart).toDate()
            this.$refs.filterPanel.formData.execTimeEnd = dayjs(execTimeEnd).toDate()
            this.$refs.filterPanel.formData.taskStatus = statusList
          }, 500)
        }
      }
    }
  },
  created() {
    const { taskStatus,execTimeStart,execTimeEnd,roleCode,parentCode='',childCode='',dealerProvinceCode='',dealerCityCode='',dealerIds='' } = this.$route.query
    if (taskStatus) {
      this.roleCode = roleCode
      this.defaultDateType = ''
      const list = this.dictHash['1025']
      const status = taskStatus.split(',')
      const statusList = list.filter(e=>status.includes(e.code)).map(({ id,name,code })=>({
        id,
        label: name,
        value: code
      }))
      console.log('statusList:',statusList)
      this.filterParamsHander({ 
        execTimeStart,
        execTimeEnd,
        taskStatus: statusList,
        parentCode: { value: parentCode },
        childCode: { value: childCode ? childCode.split(',') : [] },
        dealerProvinceCode: { value: dealerProvinceCode },
        dealerCityCode: { value: dealerCityCode },
        dealerIds: { value: dealerIds ? dealerIds.split(',') : [] }
      })
      // 漏斗中数据回显
      // this.$nextTick(()=>{
      //   this.$refs.filterPanel.formData.execTimeStart = dayjs(execTimeStart).toDate()
      //   this.$refs.filterPanel.formData.execTimeEnd = dayjs(execTimeEnd).toDate()
      //   this.$refs.filterPanel.formData.taskStatus = statusList
      // })
    }
  },
  activated() {
    this.onRefresh()
  },
  methods:{
    onSort(data) {
      console.log('onSort', data)
      this.sort = data
      this.onRefresh()
    },
    async taskCount(){
      const temp = {}
      const params = {
        planTime: {
          start: this.selectTime[0],
          end: this.selectTime[1],
        },
        taskStatus: this.filterParams?.taskStatus || [],
        execTime: this.filterParams?.execTime || { start: '',end:'' },
        createTime: this.filterParams?.createTime || { start: '',end:'' },
        roleCode: this.roleCode || '',
        executorId: this.filterParams?.executorId || '',
        overdue: this.filterParams.overdue,
        parentCode: this.filterParams.parentCode || '',
        childCode: this.filterParams.childCode || [],
        dealerProvinceCode: this.filterParams.dealerProvinceCode || '',
        dealerCityCode: this.filterParams.dealerCityCode || '',
        dealerIds: this.filterParams.dealerIds || [],
      }
      const res = await taskStatistic(params)
      res.forEach(e => {
        temp[e.type] = e.count
      })
      this.$set(this,'countInfo',temp)
    },
    taskCodeHandle(){
      const taskCodes = []
      this.subType.forEach(item=>{
        if (item.includes(',')){
          taskCodes.push(...item.split(','))
        } else {
          taskCodes.push(item)
        }
      })
      return taskCodes
    },
    fetchApi({ currentPage,pageSize }) {
      const params = {
        pageNum: currentPage,
        pageSize: pageSize,
        type: this.type,
        planTime: {
          start: this.selectTime[0],
          end: this.selectTime[1],
        },
        sort: this.sort,
        taskCodes: this.subType, 
        taskStatus: this.filterParams?.taskStatus || [],
        execTime: this.filterParams?.execTime || { start: '',end:'' },
        createTime: this.filterParams?.createTime || { start: '',end:'' },
        roleCode: this.roleCode || '',
        executorId: this.filterParams?.executorId || '',
        overdue: this.filterParams.overdue,
        parentCode: this.filterParams.parentCode || '',
        childCode: this.filterParams.childCode || [],
        dealerProvinceCode: this.filterParams.dealerProvinceCode || '',
        dealerCityCode: this.filterParams.dealerCityCode || '',
        dealerIds: this.filterParams.dealerIds || [],
      }
      loading.showLoading()
      return taskListFetch(params).then((res) => {
        loading.hideLoading()
        console.log(res,'999')
        this.totalCount = res.page.totalCount
        res.dataList.forEach((item) => (item.checked = false))
        return res
      })
    },
    changeTab(typeItem){
      this.type = typeItem.value
      this.subType = []
      if (this.type == 101) {
        this.$refs.calendarPicker.onDateShortcut('')
        return
      }
      const dataText = this.$refs.calendarPicker.dataText
      this.$refs.calendarPicker.onDateShortcut(dataText ? dataText : 'today')
      // this.$refs.calendarPicker.onDateShortcut(dataText)
    },
    onChange({ value }){
      const idx = this.subType.indexOf(value)
      if (idx===-1) this.subType.push(value)
      else this.subType.splice(idx,1)

      this.$refs.list.onRefresh()
    },
    dateConfirm(){
      this.onRefresh()
    },
    filterParamsHander(
      { execTimeStart,
        execTimeEnd,
        taskStatus,
        executorId: executor,
        createTimeStart,
        createTimeEnd,
        overdue,
        parentCode,
        childCode,
        dealerProvinceCode,
        dealerCityCode,
        dealerIds
      }
    ){
      const temp = {}
      if (execTimeStart || execTimeEnd){
        temp.execTime = { start: '',end:'' }
      }
      if (createTimeStart || createTimeEnd){
        temp.createTime = { start: '',end:'' }
      }
      if (execTimeStart) temp.execTime.start = dayjs(execTimeStart).format('YYYY-MM-DD 00:00:00')
      if (execTimeEnd) temp.execTime.end = dayjs(execTimeEnd).format('YYYY-MM-DD 23:59:59')

      if (createTimeStart) temp.createTime.start = dayjs(createTimeStart).format('YYYY-MM-DD 00:00:00')
      
      if (createTimeEnd) temp.createTime.end = dayjs(createTimeEnd).format('YYYY-MM-DD 23:59:59') 
      if (taskStatus && taskStatus.length){
        temp.taskStatus = taskStatus.map(e=>e.value)
      }
      if (executor?.id){
        temp.executorId = executor.id
      }
      if (parentCode && parentCode.value) {
        temp.parentCode = parentCode.value
      }
      if (childCode && childCode.value) {
        temp.childCode = childCode.value
      }
      if (dealerProvinceCode && dealerProvinceCode.value) {
        temp.dealerProvinceCode = dealerProvinceCode.value
      }
      if (dealerCityCode && dealerCityCode.value) {
        temp.dealerCityCode = dealerCityCode.value
      }
      if (dealerIds && dealerIds.value) {
        temp.dealerIds = dealerIds.value
      }
      temp.overdue = overdue?.value
      this.$set(this,'filterParams',temp)
      console.log('任务明细', 'filterParams', temp)
    },
    onFilter(filterParams){
      this.isFirst = false
      this.filterParamsHander(filterParams)
      this.filterPanelVisible = false
      this.onRefresh()
    },
    onRefresh(){
      this.taskCount()
      this.$refs?.list?.onRefresh()
    },
    async createFilterOptions(){
      let ret = []
      const dictHash = this.$store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        let list = dictHash[dictType] || []
        return list.map(({ code: value, id, name: label }) => ({
          id,
          label,
          value,
        }))
      }
      if (!this.isDealer) {
        const { parentCode='',
          childCode='',
          dealerProvinceCode='',
          dealerCityCode='',
          dealerIds=''
        } = this.$route.query
        const parentList = await salesAssistantService.getRegionTreeApi()
        // 大区
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('大区'),
          field: 'parentCode',
          defaultValue: parentCode,
          options: [],
          options: parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
        })
        // 城市区域
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市区域'),
          multiple: true,
          field: 'childCode',
          defaultValue: childCode,
          options: [],
        })
        const provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
        // 省份
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('省份'),
          field: 'dealerProvinceCode',
          defaultValue: dealerProvinceCode,
          options: provinceList.map(({ code, name }) => ({ id: code, code, name })),
        })
        // 城市
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市'),
          field: 'dealerCityCode',
          defaultValue: dealerCityCode,
          options: [],
        })
        // 销售门店
        const dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '' })
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('销售门店'),
          multiple: true,
          field: 'dealerIds',
          defaultValue: dealerIds,
          options: dealerResult.dataList.map((item) => { return { code: item.id, name: item.name } })
        })
      }
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('状态'),
        field: 'taskStatus',
        multiple: true,
        options: getOptionsByDictType(1025),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('是否逾期'),
        field: 'overdue',
        multiple: false,
        options: [
          { id: uuidv4(), label: this.$t('是'), value: 1 },
          { id: uuidv4(), label: this.$t('否'), value: 0 },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'date',
        label: this.$t('实际完成时间'),
        start: {
          field: 'execTimeStart',
        },
        end: {
          field: 'execTimeEnd',
        },
      })
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'date',
        label: this.$t('任务创建时间'),
        start: {
          field: 'createTimeStart',
        },
        end: {
          field: 'createTimeEnd',
        },
      })

      const dccStaffList = await baseDataServices.getStaffList({ dealerId: this.userInfo.dealers[0]?.id })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('实际执行人'),
        field: 'executorId', 
        multiple: false,
        options: dccStaffList.map((item) => ({ ...item, label: item.name, value: item.id })),
      })
      
      this.filterOptions = ret
    },
    toDetails(item){
      const urlMap = {
        1:'/clue-details', 
        2: item.taskCode === 'APPOINT_STORE_PDC' ? '/appointment-detail' : '/appointment-drive-detail', 
        3:'/order-detail',
        4:'/order-detail', // ?id=1790341936516947969
      }
      const queryMap = {
        1:{ id: item.leadId },
        2:{ id: item.relationId,clueId:item.leadId },
        3:{ id: item.relationId },
        4:{ id: item.relationId },
      }
      const url = urlMap[this.type]
      if (url){
        this.$router.push({
          path: url,
          query: queryMap[this.type]
        })
      }
      if (item.taskCode === 'WECHAT_TO_LEAD') {
        this.openChat(item)
      }
    },
    async openChat(data) {
      let obj = { externalUserIds: data.customerWechatId, chatId: '' }
      wx.openEnterpriseChat({
        ...obj,
        success: function(res) {
          // var chatId = res.chatId // 返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
          // 回调
        },
        fail: (res)=> {
          if (res.errMsg.indexOf('function not exist') > -1){
            alert(this.$t('版本过低请升级'))
          } else if (res.errMsg.indexOf('openEnterpriseChat:invalid chatid') >-1){
            this.$toast('你不是群成员，无法参与会话')
          } else {
            this.$toast(res.errMsg)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@head-height: 48px;
/deep/.sort-wrap {
  ul {
    padding-top: 0;
    li {
      margin-left: 12px;
      &:last-child {
        margin-right: 12px;
      }
    }
  }

}
.taskManage{
  display: flex;
  height: 100vh;
  overflow: hidden;
  .taskManage-l{
    font-size: 14px;
    width: 100px;
    border-right: 2px solid #fff;
    overflow-y: auto;
    .type-title{
      height: @head-height;
      line-height: @head-height;
      border-bottom: 1px solid #cdcdcd;
      background: #fff;
      padding-left: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .type-item{
      cursor: pointer;
      padding: 12px;
      text-transform: capitalize;
    }
    .current{
      color: #B9921A;
      background: #fff;
    }
  }
  .taskManage-r{
    overflow-y: auto;
    width: calc(100% - 100px);
    // border: 1px solid;
    .top-wrap {
      position: sticky;
      top: 0;
      z-index: 1;
      background: #fff;
    }
    .filter-panel{
      height: @head-height;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 1px solid #cdcdcd;
      background: #fff;
      padding-right: 12px;
      .img{
        width: 24px;
        height: 24px;
        margin-left: 14px;
        cursor: pointer;
      }
    }
    .sub-tab{
      display: flex;
      flex-wrap: wrap;
      padding: 12px;
      background: #fff;
      
      .sub-item,.active{
        border: 1px solid #B9921A;
        line-height: 22px;
        padding: 0 8px;
        font-size: 13px;
        margin: 8px 10px 0 0;
        cursor: pointer;
        user-select: none;
      }
      .sub-item{
        color: #B9921A;
        background-color: #fff;
      }
      .active{
        color: #fff;
        background-color: #B9921A;
      }
    }
    .total{
      line-height: 28px;
      font-size: 14px;
      padding-left: 12px;
    }
    .task-manage-list{
      /deep/.ul{
        padding: 10px;
      }
    }
  }
}
</style>
